<template>
  <div class="cr-card-detail-option" v-if="show">
    <v-icon>
      mdi-domain
    </v-icon>

    <div class="d-flex" v-if="!isEdit">
      <div class="mr-1" v-if="company">{{ company }}</div>
      <div class="mr-1" v-if="organ">{{ organ }}</div>
      <div class="mr-1" v-if="position">{{ position }}</div>
    </div>

    <v-row v-else>
      <v-col cols="4">
        <TextField label="회사" class="mb-2" :value.sync="company" />
      </v-col>
      <v-col cols="4">
        <TextField label="부서" class="mb-2" :value.sync="organ" />
      </v-col>
      <v-col cols="4">
        <TextField label="직급" :value.sync="position" />
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { mapGetters } from "vuex";
// import { isBlank } from "@/commons/utils/validation";
import TextField from "@/contact/views/components/list/common/TextField.vue";

export default {
  components: { TextField },
  data({ $store: { getters: g } }) {
    const { company, organ, position } = g["cardDetail/detail"];
    return {
      company: company ?? "",
      organ: organ ?? "",
      position: position ?? ""
    };
  },
  computed: {
    ...mapGetters("cardDetail", ["isEdit", "detail"]),
    show() {
      return true;
      /*
      if (this.isEdit) return true;
      return !isBlank(this.company + this.organ + this.position);
       */
    }
  },
  watch: {
    isEdit(isEdit) {
      if (isEdit) return;

      this.company = this.detail?.company ?? "";
      this.organ = this.detail?.organ ?? "";
      this.position = this.detail?.position ?? "";
    }
  },
  methods: {
    getData() {
      if (!this.isEdit) return;

      const { company, organ, position } = this;
      return { company, organ, position };
    }
  }
};
</script>
